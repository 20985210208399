<template>
  <main class="approach">
    <!--
    //# Section Title -->
    <section class="l-title align-center" data-scroll-section>
      <h1 class="title title-general" data-scroll="fade-up" data-scroll-delay="1">The Approach</h1>
      <div class="text-c"  style="text-align: justify"  data-scroll="fade-up" data-scroll-delay="2">
        <p class="text" >
          The Diagnostic Process brings together a team of expert consultants and key client stakeholders prior to launching any project.
        </p>
        <p class="text">
          Using a proprietary form of guided questioning, the goal is to gain insight, uncover a lack of alignment and reveal unspoken agendas before laying the groundwork for the development of lateral solutions.
        </p>
        <p class="text">
          Interactive client/consultant partnership from the outset mitigates risk and drives powerful results.
        </p>
        <p class="text">
          Clients experience our team in action and receive consultative value before the engagement has formally begun.
        </p>
      </div>
    </section>
    <!--
    //# Section Title -->
    <section class="l-questioning align-center" data-scroll-section>
      <h3 class="subtitle" data-scroll="fade-up">The Questioning focuses on Familiar Territory</h3>
      <ul class="quest-list">
        <li class="quest-item" data-scroll="fade-up" data-scroll-delay="1">
          <div class="circle-c">
            <span class="btn-circular btn-orange active"><span class="buttons-name">Strategy</span></span>
          </div>
          <ul class="description-list">
            <li class="description-item">
              <p class="text">What is the business strategy for this unit?</p>
            </li>
            <li class="description-item">
              <p class="text">What is the plan for how it will be achieved?</p>
            </li>
            <li class="description-item">
              <p class="text">
                What are the critical success factors to ensure achievement of the plan?
              </p>
            </li>
            <li class="description-item">
              <p class="text">Where do the risks/dangers lie?</p>
            </li>
          </ul>
        </li>
        <li class="quest-item" data-scroll="fade-up" data-scroll-delay="2">
          <div class="circle-c">
            <span class="btn-circular btn-orange active"><span class="buttons-name">structure</span></span>
          </div>
          <ul class="description-list">
            <li class="description-item">
              <p class="text">What is the cultural environment?</p>
            </li>
            <li class="description-item">
              <p class="text">
                How does work get done and how are decisions made?
              </p>
            </li>
            <li class="description-item">
              <p class="text">
                What is the political framework of the company?
              </p>
            </li>
            <li class="description-item">
              <p class="text">How is the business governed?</p>
            </li>
          </ul>
        </li>
        <li class="quest-item" data-scroll="fade-up" data-scroll-delay="3">
          <div class="circle-c">
            <span class="btn-circular btn-orange active"><span class="buttons-name">skills</span></span>
          </div>
          <ul class="description-list">
            <li class="description-item">
              <p class="text">
                What skills and competencies are required to achieve the plan?
              </p>
            </li>
            <li class="description-item">
              <p class="text">
                Is the right talent resident in the organization today?
              </p>
            </li>
            <li class="description-item">
              <p class="text">
                How will the right skills be developed, acquired or deployed in support of the plan?
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </section>
    <!--
    //# Section Cards Mechanism Diagnostic -->
    <section class="l-diagnostic align-center" data-scroll-section>
      <h3 class="subtitle" data-scroll="fade-up">
        Underlying Mechanism of the Diagnostic
      </h3>
      <figure class="separator" data-scroll="fade-up">
        <img alt="work" :src="require(`@/assets/separator-light.png`)" />
      </figure>
      <ul class="diagnostic-list">
        <li class="diagnostic-item" data-scroll="fade-up" data-scroll-delay="1">
          <div class="c-card c-card-lines">
            <p class="text">Enables key stakeholders to participate individually and with candor</p>
          </div>
        </li>
        <li class="diagnostic-item" data-scroll="fade-up" data-scroll-delay="2">
          <div class="c-card c-card-lines">
            <p class="text">Encourages openness by sharing commonalities</p>
          </div>
        </li>
        <li class="diagnostic-item" data-scroll="fade-up" data-scroll-delay="3">
          <div class="c-card c-card-lines">
            <p class="text">Probes on differences but without value judgement or attribution</p>
          </div>
        </li>
        <li class="diagnostic-item" data-scroll="fade-up" data-scroll-delay="4">
          <div class="c-card c-card-lines">
            <p class="text">Maintains objectivity without prompting or driving opinion</p>
          </div>
        </li>
        <li class="diagnostic-item" data-scroll="fade-up" data-scroll-delay="5">
          <div class="c-card c-card-lines">
            <p class="text">Recognizes and responds to body language and masked emotional signals</p>
          </div>
        </li>
      </ul>
    </section>
    <!--
    //# Section Cards Value Diagnostic -->
    <section class="l-diagnostic align-center" data-scroll-section>
      <h3 class="subtitle" data-scroll="fade-up">Value of the Diagnostic</h3>
      <figure class="separator" data-scroll="fade-up">
        <img alt="work" :src="require(`@/assets/separator-light.png`)" />
      </figure>
      <ul class="diagnostic-list">
        <li class="diagnostic-item" data-scroll="fade-up" data-scroll-delay="1">
          <div class="c-card">
            <p class="text">Identifies lack of alignment among key executive stakeholders</p>
          </div>
        </li>
        <li class="diagnostic-item" data-scroll="fade-up" data-scroll-delay="2">
          <div class="c-card">
            <p class="text">Provides a deep insight into the heart of the challenge, often surfacing previously unrecognized aspects</p>
          </div>
        </li>
        <li class="diagnostic-item" data-scroll="fade-up" data-scroll-delay="3">
          <div class="c-card">
            <p class="text">Creates the basis for the development of a truly bespoke solution</p>
          </div>
        </li>
        <li class="diagnostic-item" data-scroll="fade-up" data-scroll-delay="4">
          <div class="c-card">
            <p class="text">Captures both creative and lateral approaches</p>
          </div>
        </li>
        <li class="diagnostic-item" data-scroll="fade-up" data-scroll-delay="5">
          <div class="c-card">
            <p class="text">Delivers more robust outcomes</p>
          </div>
        </li>
      </ul>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Approach',
  metaInfo() {
      return { 
          title: "Ascentador | The Approach",
          meta: [
              { name: 'description', content:  'The Diagnostic Process brings together a team of expert consultants and key client stakeholders prior to launching any project. Using a proprietary form of guided questioning, the goal is to gain insight, uncover a lack of alignment and reveal unspoken agendas before laying the groundwork for the development of lateral solutions. '},
          ]
      }
  },
  mounted() {
    this.$emit('view-mounted');
  },
};
</script>
